@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500&display=swap');
@import 'antd/dist/reset.css';

pre,
code,
kbd,
samp,
pre *,
code *,
kbd *,
samp * {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace !important;
}

html,
body,
* {
  font-family: 'Arimo', sans-serif !important;
}

body {
  font-size: 16px;
  background-color: #f0f2f5;
}


.ant-layout-header {
  background: #fff;
  padding: 0;
  height: auto;
  min-height: 64px;
  border-bottom: 1px solid #e0e0e0;
}

.ant-layout-content {
  background-color: rgb(249, 250, 251);
  min-height: 600px;
}

.ant-menu-horizontal {
  border: none;
}

.ant-menu-horizontal>.ant-menu-item a {
  color: rgba(107, 114, 128, 1);
  font-weight: 400;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
  color: rgba(17, 24, 39, 1);
  font-weight: 500;
}


.menu-card {
  @apply flex flex-col justify-center items-center bg-white h-screen py-40;
}

.center-content {
  @apply flex flex-col justify-center items-center;
}


.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
  border: none;
}

.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}

.bg-indigo-600 {
  background-color: rgb(79, 70, 229);
}

.card-table-no-placeholder .ant-table-placeholder {
  display: none;
}

.card-table .ant-pagination {
  padding: 0 16px;
}

.card-table .ant-empty {
  height: 200px;
}

.card-table .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
}

.card-table .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}

.select-rows-per-page-en .ant-select-selector::before {
  content: 'Rows per page';
  width: 104px;
  align-self: center;
}

.nb-code {
  margin-bottom: 1em;
}

.nb-code pre {
  margin-bottom: 0;
}

.nb-code code {
  border-radius: .25em;
}

.ant-steps-item-title {
  color: rgba(0, 0, 0, .85) !important;
}

.access-control-table .tooltip-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.access-control.input-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.access-control.ant-drawer-subtitle {
  line-height: 22px;
  margin: 24px 0;
}

.ant-steps-item-icon {
  background: #EBEBEB !important;
  border-color: #EBEBEB !important;
}

.ant-steps-icon-dot {
  background: #EBEBEB !important;
  border-color: #EBEBEB !important;
}

.ant-steps-icon {
  background: #EBEBEB !important;
  border: none;
  color: black !important;
}

.ant-steps-item-tail::after {
  background: #EBEBEB !important;
}

.ant-steps-item-tail {
  border: none;
}

td.non-highlighted-table-column {
  background-color: #FFFFFF !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background: #FAFAFA !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: normal !important;
}

.CustomPopupCollapse .ant-collapse-content-box,
.CustomPopupCollapse .ant-collapse-header {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.system-info-panel .ant-collapse-header {
  padding: 0 !important;
}

.system-info-panel .ant-collapse-content-box {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.peers-form .ant-layout-header {
  line-height: 25px;
  height: auto;
  min-height: auto;
  padding-bottom: 15px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-modal-content {
  border-radius: 0px !important;
}

.ant-form-item-explain-error {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-top: 2px;
}

.menlo-font,
.menlo-font * {
  font-family: 'Menlo', monospace !important;
}

.arimo-font,
.arimo-font * {
  font-family: 'Arimo', sans-serif !important;
}

.tag-box .ant-select-selector {
  padding: 0 5px !important;
}

.tag-box .ant-select-selection-item {
  width: 100%;
  line-height: 20px;
  justify-content: center;
  list-style: none;
  display: flex;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.2s;
  text-align: start;
  max-width: 40px;
  height: 25px;
  padding: 0 4px !important;
  align-items: center;
  margin-top: 3px;
  text-align: center;
}

.w-100 {
  width: 100%;
}

.font-500 {
  font-weight: 500;
}

.page-heading {
  font-weight: 500 !important;
  font-size: 22px !important;
  margin: 0 !important;
}

.ant-tag {
  font-weight: 400 !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__control,
.react-select__value-container,
.react-select__input-container {
  min-height: 32px !important;
  padding: 0 5px !important;
  max-height: 32px !important;
  margin: 0 !important;
}

.react-select__value-container {
  align-items: center !important;
}

.react-select__indicator {
  padding: 0 5px !important;
}

.ant-badge-status-dot {
  width: 8px !important;
  height: 8px !important;
}

.routes-accordian {
  margin-top: 20px;
}

.routes-accordian .ant-collapse-header {
  background: #fff;
  align-items: center !important;
}

.ant-collapse-content-box {
  background: #FAFAFA;
  padding: 5px 16px !important;
}

.accordian-header p {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  width: 25%;
}

.headerInner {
  display: flex;
  height: 45px;
}

.headerInner p {
  margin: 0;
  width: 33.33%;
  display: flex;
  align-items: center;
}

.accordian-header {
  display: flex;
  padding: 10px 40px;
}

.accordian-inner-header {
  display: flex;
  padding: 10px 40px;
  margin-bottom: 10px;
}

.accordian-inner-header p {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  width: 20%;
}

.accordian-inner-listing {
  padding: 3px 40px;
  display: flex;
  align-items: center;
}

.accordian-inner-listing p {
  font-size: 14px;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  width: 10%;
  margin: 0;
}

.headerInner .text-right {
  display: block;
  text-align: right;
  padding-right: 40px;
}

.accordian-inner-listing p:nth-child(5) {
  text-align: right;
}

.text-center {
  text-align: center;
}


.accordian-inner-listing p:nth-child(1) {
  width: 25%;
  padding-right: 0;
}

.accordian-inner-header p:nth-child(1) {
  width: calc(20% + 50px);
}

.accordian-inner-listing p:nth-child(2) {
  width: 25%;
}

.accordian-inner-header p:nth-child(2) {
  width: 25%;
}

.accordian-inner-listing p:nth-child(3) {
  width: 20%;
}

.accordian-inner-listing p:nth-child(4) {
  width: 20%;
}

.ant-list-item {
  padding: 5px 0 !important;
  margin-inline: 0 !important;
}

ul.ant-list-items {
  margin-top: 5px !important;
}

.container-spinner {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgb(0 0 0 / 2%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.remove-bg .ant-collapse-content-box {
  background-color: transparent;
}

.route-form .ant-form-item {
  margin-bottom: 24px;
}

.nb-code {
  position: relative;
}

.btn-copy-code.peer {
  position: absolute;
  top: 10px;
  right: 2px;
}

.blue-color {
  color: #1890FF;
}

.style-like-text .ant-select-selector {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 1) !important;
}

.style-like-text .ant-select-selection-search::after {
  display: none !important;
}

.style-like-text .ant-select-selection-item {
  padding-inline-end: 0 !important;
}

.style-like-text .ant-select-arrow {
  display: none !important;
}


.ant-spin-nested-loading .ant-spin-spinning {
  min-height: 300px;
}

.route-form.edit-form-wrapper .ant-form-item {
  margin-bottom: 39px;
}

.groupsSelect {
  max-width: 200px;
  width: 100%;
}

@media (max-width: 1220px) {
  .groupsSelect{
    margin-left: 0;
    margin-top: 15px;
  }
}